* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
}

div#root {
  min-height: 100%;
  display: flex;
}
